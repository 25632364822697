import React from 'react';
import PropTypes from 'prop-types';
import Img from "gatsby-image";

const CategoryImage = ({image }) => (

    <React.Fragment>
         <Img className='image-align' fluid={image}  alt='sdjvgjdsf' />  
    </React.Fragment>
);

CategoryImage.propTypes = {
    image: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

export default CategoryImage;