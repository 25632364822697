import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticlePreview from "../components/articlePreview"
import CategoryImage from "../components/CategoryImage"
import Masonry from "react-masonry-css"
// import Carousel from '@brainhubeu/react-carousel';
// import '@brainhubeu/react-carousel/lib/style.css';
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import axios from "axios"
import {
  getCookie,
  getBookMarkList,
  getUser,
  authenticate,
  isAuth,
} from "../components/helpers"

const responsive = {
  mobile: {
    breakpoint: { max: 450, min: 0 },
    items: 1,
  },
}
const breakpointColumnsObj = {
  default: 2,
  500: 1,
}
function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }
  return array
}
const Articles = ({ data }) => {
  console.log("getBookMarkList", getBookMarkList())

  const articles = data.allArticles.nodes
  const jumbledArticles = shuffle(articles).slice(0, 4)

  const weddingArticles = data.wedding.nodes
  const weddingImage =
    data.wedding_image.relationships.field_img.localFile.childImageSharp.fluid
  const jewelleryArticles = data.jewellery.nodes
  const jewelleryImage =
    data.jewellery_image.relationships.field_img.localFile.childImageSharp.fluid
  const ideasArticles = data.ideas.nodes
  const ideasImage =
    data.ideas_image.relationships.field_img.localFile.childImageSharp.fluid
  const planningArticles = data.planning.nodes
  const planningImage =
    data.planning_image.relationships.field_img.localFile.childImageSharp.fluid
  const articleArray = getBookMarkList()
  return (
    <Layout>
      <SEO title="Kalyan Shastra Home" />
      <section className="greenbg" id="section1">
        <div className="container">
          <div id="overlay-green"></div>
          <div className="green-left"></div>
          <div className="green-right"></div>
          <div className="row justify-content-center headRow">
            <div className="col-10">
              <p className="headerTxt">
                Marriages Are Made in Heaven <br />
                We Help Plan it on Earth
              </p>
            </div>
          </div>
          <Carousel
            responsive={responsive}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            ssr={true}
            showDots={true}
            // customTransition="all .5"
            containerClass="carousel-container"
            itemClass="carousel-item-padding-40-px"
            className="d-sm-none"
          >
            {jumbledArticles.map(article => (
              <div>
                {
                  <div className="mx-2">
                    <ArticlePreview
                      key={article.id}
                      articleId={article.drupal_id}
                      title={article.title}
                      path={article.path.alias}
                      image={
                        article.relationships.field_image.localFile
                          .childImageSharp.fluid
                      }
                      tag={article.relationships.field_tags[0].name}
                      alt={article.field_image.alt}
                      summary={
                        article.body.summary
                          ? article.body.summary
                          : article.body.processed.substring(0, 300)
                      }
                      isBookMarked={
                        articleArray.indexOf(article.drupal_id) != -1 &&
                        isAuth()
                          ? true
                          : false
                      }
                    />
                    <div className="clearFix"></div>
                  </div>
                }
              </div>
            ))}
          </Carousel>
          <div className="row d-sm-flex d-none" id="flex-row">
            {jumbledArticles.map(article => (
              <div className="col-12 col-sm-6 col-lg-3">
                <ArticlePreview
                  key={article.id}
                  articleId={article.drupal_id}
                  title={article.title}
                  path={article.path.alias}
                  image={
                    article.relationships.field_image.localFile.childImageSharp
                      .fluid
                  }
                  tag={article.relationships.field_tags[0].name}
                  alt={article.field_image.alt}
                  summary={
                    article.body.summary
                      ? article.body.summary
                      : article.body.processed.substring(0, 300)
                  }
                  isBookMarked={
                    articleArray.indexOf(article.drupal_id) != -1 && isAuth()
                      ? true
                      : false
                  }
                />
              </div>
            ))}
          </div>

          <div className="row showMoreDiv">
            <div className="col-md-12">
              <Link className="showMore float-right" to="/articles/">
                Show More...
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowbg sectionClass" id="wedding">
        <div className="yellow-left"></div>
        <div className="yellow-center"></div>
        <div className="yellow-right"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="secHeading">Wedding Rituals</h3>
              <p className="sub-headers">
                <strong>The Intrinsic Part of the Great Indian Wedding</strong>
                <br />
                Each Indian community has its fair share of wedding rituals and
                traditions. Checkout community-wise specifics of each custom and
                ritual that forms the life-essence of your wedding.
              </p>
            </div>
          </div>
          <div className="row flexRow">
            <div className="col-lg-6 d-none d-lg-block">
              <CategoryImage image={weddingImage} />
              <div className="clearFix"></div>
            </div>
            <div className="col-lg-6 col-12">
              <Carousel
                responsive={responsive}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                ssr={true}
                showDots={true}
                // customTransition="all .5"
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
                className="d-sm-none"
              >
                {weddingArticles.map(article => (
                  <div>
                    {
                      <div className="mx-2">
                        <ArticlePreview
                          key={article.id}
                          articleId={article.drupal_id}
                          title={article.title}
                          path={article.path.alias}
                          image={
                            article.relationships.field_image.localFile
                              .childImageSharp.fluid
                          }
                          tag={article.relationships.field_tags[0].name}
                          alt={article.field_image.alt}
                          summary={
                            article.body.summary
                              ? article.body.summary
                              : article.body.processed.substring(0, 300)
                          }
                          isBookMarked={
                            articleArray.indexOf(article.drupal_id) != -1 &&
                            isAuth()
                              ? true
                              : false
                          }
                        />
                        <div className="clearFix"></div>
                      </div>
                    }
                  </div>
                ))}
              </Carousel>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid d-sm-flex d-none"
                columnClassName="my-masonry-grid_column"
              >
                {weddingArticles.map(article => (
                  <div className="grid-item">
                    {
                      <div>
                        <ArticlePreview
                          key={article.id}
                          articleId={article.drupal_id}
                          title={article.title}
                          path={article.path.alias}
                          image={
                            article.relationships.field_image.localFile
                              .childImageSharp.fluid
                          }
                          tag={article.relationships.field_tags[0].name}
                          alt={article.field_image.alt}
                          summary={
                            article.body.summary
                              ? article.body.summary
                              : article.body.processed.substring(0, 300)
                          }
                          isBookMarked={
                            articleArray.indexOf(article.drupal_id) != -1 &&
                            isAuth()
                              ? true
                              : false
                          }
                        />
                        <div className="clearFix"></div>
                      </div>
                    }
                  </div>
                ))}
              </Masonry>
            </div>
          </div>
        </div>
      </section>

      <section className="pinkbg sectionClass" id="planning">
        <div className="pink-left d-lg-block d-none"></div>
        <div className="pink-right d-lg-block d-none"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="secHeading">Wedding Planning</h3>
              <p className="sub-headers">
                <strong>Helping You Plan Your Dream Wedding</strong>
                <br />
                Everything starts with a Plan! In these pages, you can find all
                that you need from Finances to Checklists that help you plan
                your D-Day to perfection.
              </p>
            </div>
          </div>
          <div className="row flexRow">
            <div className="col-lg-6 d-none d-lg-block">
              <CategoryImage image={planningImage} />
              <div className="clearFix"></div>
            </div>
            <div className="col-lg-6 col-12">
              {/* <div className="row">
                {planningArticles.map(article => (
                  <div className="col-12 col-md-6">
                    <ArticlePreview
                      key={article.id}
                      title={article.title}
                      path={article.path.alias}
                      image={article.relationships.field_image.localFile.childImageSharp.fluid}
                      tag={article.relationships.field_tags[0].name}
                      alt={article.field_image.alt}
                      summary={article.body.summary ? article.body.summary : article.body.processed.substring(0, 300)}
                    />
                    <div className="clearFix"></div>
                  </div>
                ))}

              </div> */}

              <Carousel
                responsive={responsive}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                ssr={true}
                showDots={true}
                // customTransition="all .5"
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
                className="d-sm-none"
              >
                {planningArticles.map(article => (
                  <div>
                    {
                      <div className="mx-2">
                        <ArticlePreview
                          key={article.id}
                          articleId={article.drupal_id}
                          title={article.title}
                          path={article.path.alias}
                          image={
                            article.relationships.field_image.localFile
                              .childImageSharp.fluid
                          }
                          tag={article.relationships.field_tags[0].name}
                          alt={article.field_image.alt}
                          summary={
                            article.body.summary
                              ? article.body.summary
                              : article.body.processed.substring(0, 300)
                          }
                          isBookMarked={
                            articleArray.indexOf(article.drupal_id) != -1 &&
                            isAuth()
                              ? true
                              : false
                          }
                        />
                        <div className="clearFix"></div>
                      </div>
                    }
                  </div>
                ))}
              </Carousel>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid d-sm-flex d-none"
                columnClassName="my-masonry-grid_column"
              >
                {planningArticles.map(article => (
                  <div className="grid-item">
                    {
                      <div>
                        <ArticlePreview
                          key={article.id}
                          articleId={article.drupal_id}
                          title={article.title}
                          path={article.path.alias}
                          image={
                            article.relationships.field_image.localFile
                              .childImageSharp.fluid
                          }
                          tag={article.relationships.field_tags[0].name}
                          alt={article.field_image.alt}
                          summary={
                            article.body.summary
                              ? article.body.summary
                              : article.body.processed.substring(0, 300)
                          }
                          isBookMarked={
                            articleArray.indexOf(article.drupal_id) != -1 &&
                            isAuth()
                              ? true
                              : false
                          }
                        />
                        <div className="clearFix"></div>
                      </div>
                    }
                  </div>
                ))}
              </Masonry>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowbg sectionClass" id="wedding">
        <div className="yellow-left"></div>
        <div className="yellow-center"></div>
        <div className="yellow-right"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="secHeading">Wedding Jewellery</h3>
              <p className="sub-headers">
                <strong>Complete Your Look with the Right Adornments</strong>
                <br />
                The Bridal Affair is never complete without the right Wedding
                Jewellery. Treat yourself with deeper insights into these
                special ornaments that tell Your Unique Story.
              </p>
            </div>
          </div>
          <div className="row flexRow">
            <div className="col-lg-6 d-none d-lg-block">
              <CategoryImage image={jewelleryImage} />
              <div className="clearFix"></div>
            </div>
            <div className="col-lg-6 col-12">
              <Carousel
                responsive={responsive}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                ssr={true}
                showDots={true}
                // customTransition="all .5"
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
                className="d-sm-none"
              >
                {jewelleryArticles.map(article => (
                  <div>
                    {
                      <div className="mx-2">
                        <ArticlePreview
                          key={article.id}
                          articleId={article.drupal_id}
                          title={article.title}
                          path={article.path.alias}
                          image={
                            article.relationships.field_image.localFile
                              .childImageSharp.fluid
                          }
                          tag={article.relationships.field_tags[0].name}
                          alt={article.field_image.alt}
                          summary={
                            article.body.summary
                              ? article.body.summary
                              : article.body.processed.substring(0, 300)
                          }
                          isBookMarked={
                            articleArray.indexOf(article.drupal_id) != -1 &&
                            isAuth()
                              ? true
                              : false
                          }
                        />
                        <div className="clearFix"></div>
                      </div>
                    }
                  </div>
                ))}
              </Carousel>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid d-sm-flex d-none"
                columnClassName="my-masonry-grid_column"
              >
                {jewelleryArticles.map(article => (
                  <div className="grid-item">
                    {
                      <div>
                        <ArticlePreview
                          key={article.id}
                          articleId={article.drupal_id}
                          title={article.title}
                          path={article.path.alias}
                          image={
                            article.relationships.field_image.localFile
                              .childImageSharp.fluid
                          }
                          tag={article.relationships.field_tags[0].name}
                          alt={article.field_image.alt}
                          summary={
                            article.body.summary
                              ? article.body.summary
                              : article.body.processed.substring(0, 300)
                          }
                          isBookMarked={
                            articleArray.indexOf(article.drupal_id) != -1 &&
                            isAuth()
                              ? true
                              : false
                          }
                        />
                        <div className="clearFix"></div>
                      </div>
                    }
                  </div>
                ))}
              </Masonry>
            </div>
          </div>
        </div>
      </section>
      <section className="pinkbg sectionClass" id="planning">
        <div className="pink-left d-lg-block d-none"></div>
        <div className="pink-right d-lg-block d-none"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="secHeading">Ideas & Tips</h3>
              <p className="sub-headers">
                <strong>Inspirations that help Create Your Perfect Day</strong>
                <br />
                Whether you have just started planning, or are well on your way
                making your dreams come true, you can find plenty of inputs to
                help with the journey. Discover ideas, tips and inspirations
                that can make your Perfect Wedding dreams a reality.
              </p>
            </div>
          </div>
          <div className="row flexRow">
            <div className="col-lg-6 d-none d-lg-block">
              <CategoryImage image={ideasImage} />
              <div className="clearFix"></div>
            </div>
            <div className="col-lg-6 col-12">
              <Carousel
                responsive={responsive}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                ssr={true}
                showDots={true}
                // customTransition="all .5"
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
                className="d-sm-none"
              >
                {ideasArticles.map(article => (
                  <div>
                    {
                      <div className="mx-2">
                        <ArticlePreview
                          key={article.id}
                          articleId={article.drupal_id}
                          title={article.title}
                          path={article.path.alias}
                          image={
                            article.relationships.field_image.localFile
                              .childImageSharp.fluid
                          }
                          tag={article.relationships.field_tags[0].name}
                          alt={article.field_image.alt}
                          summary={
                            article.body.summary
                              ? article.body.summary
                              : article.body.processed.substring(0, 300)
                          }
                          isBookMarked={
                            articleArray.indexOf(article.drupal_id) != -1 &&
                            isAuth()
                              ? true
                              : false
                          }
                        />
                        <div className="clearFix"></div>
                      </div>
                    }
                  </div>
                ))}
              </Carousel>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid d-sm-flex d-none"
                columnClassName="my-masonry-grid_column"
              >
                {ideasArticles.map(article => (
                  <div className="grid-item">
                    {
                      <div>
                        <ArticlePreview
                          key={article.id}
                          articleId={article.drupal_id}
                          title={article.title}
                          path={article.path.alias}
                          image={
                            article.relationships.field_image.localFile
                              .childImageSharp.fluid
                          }
                          tag={article.relationships.field_tags[0].name}
                          alt={article.field_image.alt}
                          summary={
                            article.body.summary
                              ? article.body.summary
                              : article.body.processed.substring(0, 300)
                          }
                          isBookMarked={
                            articleArray.indexOf(article.drupal_id) != -1 &&
                            isAuth()
                              ? true
                              : false
                          }
                        />
                        <div className="clearFix"></div>
                      </div>
                    }
                  </div>
                ))}
              </Masonry>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

Articles.propTypes = {
  data: PropTypes.object.isRequired,
}

export const data = graphql`
  {
    allArticles: allNodeArticle {
      nodes {
        id
        drupal_id
        title
        created
        body {
          processed
          summary
        }
        path {
          alias
        }
        field_image {
          alt
        }
        relationships {
          field_tags {
            ... on taxonomy_term__kalyan_shastra_categories {
              id
              name
            }
          }
          field_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    jewellery: allNodeArticle(
      limit: 4
      filter: {
        relationships: {
          field_tags: {
            elemMatch: { id: { eq: "8c9463ac-0687-5f45-9cbe-56c39d8a8ba6" } }
          }
        }
      }
      sort: { fields: created, order: DESC }
    ) {
      nodes {
        id
        drupal_id
        title
        body {
          processed
          summary
        }
        path {
          alias
        }
        field_image {
          alt
        }
        relationships {
          field_tags {
            ... on taxonomy_term__kalyan_shastra_categories {
              id
              name
            }
          }
          field_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    jewellery_image: taxonomyTermKalyanShastraCategories(
      id: { eq: "8c9463ac-0687-5f45-9cbe-56c39d8a8ba6" }
    ) {
      relationships {
        field_img {
          localFile {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    wedding: allNodeArticle(
      limit: 4
      filter: {
        relationships: {
          field_tags: {
            elemMatch: { id: { eq: "f7342040-b19d-5fff-a235-4e49be5a6db6" } }
          }
        }
      }
      sort: { fields: created, order: DESC }
    ) {
      nodes {
        id
        drupal_id
        title
        body {
          processed
          summary
        }
        path {
          alias
        }
        field_image {
          alt
        }
        relationships {
          field_tags {
            ... on taxonomy_term__kalyan_shastra_categories {
              id
              name
            }
          }
          field_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    wedding_image: taxonomyTermKalyanShastraCategories(
      id: { eq: "f7342040-b19d-5fff-a235-4e49be5a6db6" }
    ) {
      relationships {
        field_img {
          localFile {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    ideas: allNodeArticle(
      limit: 4
      filter: {
        relationships: {
          field_tags: {
            elemMatch: { id: { eq: "3de534c7-6922-504d-a3ea-42f56449a127" } }
          }
        }
      }
      sort: { fields: created, order: DESC }
    ) {
      nodes {
        id
        drupal_id
        title
        body {
          processed
          summary
        }
        path {
          alias
        }
        field_image {
          alt
        }
        relationships {
          field_tags {
            ... on taxonomy_term__kalyan_shastra_categories {
              id
              name
            }
          }
          field_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    ideas_image: taxonomyTermKalyanShastraCategories(
      id: { eq: "3de534c7-6922-504d-a3ea-42f56449a127" }
    ) {
      relationships {
        field_img {
          localFile {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    planning: allNodeArticle(
      limit: 4
      filter: {
        relationships: {
          field_tags: {
            elemMatch: { id: { eq: "151a5179-a6ef-5015-bdd9-4c14d5ed45ee" } }
          }
        }
      }
      sort: { fields: created, order: DESC }
    ) {
      nodes {
        id
        drupal_id
        title
        body {
          processed
          summary
        }
        path {
          alias
        }
        field_image {
          alt
        }
        relationships {
          field_tags {
            ... on taxonomy_term__kalyan_shastra_categories {
              id
              name
            }
          }
          field_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    planning_image: taxonomyTermKalyanShastraCategories(
      id: { eq: "151a5179-a6ef-5015-bdd9-4c14d5ed45ee" }
    ) {
      relationships {
        field_img {
          localFile {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    allMenu: allTaxonomyTermKalyanShastraCategories(
      sort: { order: ASC, fields: relationships___parent }
      filter: { id: { eq: "f7342040-b19d-5fff-a235-4e49be5a6db6" } }
    ) {
      edges {
        node {
          id
          name
          path {
            alias
          }
          relationships {
            taxonomy_term__kalyan_shastra_categories {
              id
              name
              relationships {
                taxonomy_term__kalyan_shastra_categories {
                  name
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Articles
